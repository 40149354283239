<template>
  <a-drawer
    :title="titleText"
    :maskClosable="false"
    width="650"
    placement="right"
    @close="handleClose()"
    :visible="drawerShow"
    :closable="false"
    style="height: calc(100% - 55px); overflow: auto; padding-bottom: 53px"
  >
    <a-form-model
      ref="ruleForm"
      :validateOnRuleChange="true"
      :model="form"
      :form="form"

    >
      <a-form-model-item label="员工姓名" prop="username">
        <span>{{form.username}}</span>
      </a-form-model-item>
      <a-form-model-item label="是否完成" prop="trainingMethod">
        <a-select
          v-model="form.status"
          placeholder="选择是否完成"
        >
          <a-select-option
            v-for="(val, key) in status"
            :value="val.keyy"
            :key="key"
            >{{ val.valuee }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="得分" prop="examUrl">
        <a-input-number :min="0" v-model="form.score" />
      </a-form-model-item>
      <a-form-model-item label="未完成原因" prop="teacher" v-if="form.status=='0'">
        <a-input placeholder="输入未完成原因" v-model="form.reason" />
      </a-form-model-item>
    </a-form-model>
    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="handleClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="handleClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import { mapState } from "vuex";
import TrainingCourse from "./TrainingCourse.vue";
import UserGroupInfo from "@/views/system/userGroup/UserGroupInfo.vue";

export default {
  name: "courseResult",
  components: { TrainingCourse, UserGroupInfo },
  props: {},
  data() {
    return {
      data: [],
      loading: false,
      titleText: "成绩录入",
      drawerShow: false,
      form: {
        id: undefined,
        username: undefined,
        status: undefined,
        score: undefined,
        reason: undefined,
      },
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.dict.dicts.training_user_status,
    }),
  },
  methods: {
    setForm(data) {
        for (let key in data) {
          this.form[key] = data[key];
        }
      this.drawerShow = true;
    },
    handleSubmit() {
      this.$put(`training-user`, {...this.form}).then((r) => {
        this.$message.success(r.data.message);
        this.handleClose();
        this.$emit("success");
        console.log(r);
      });
    },
    handleClose() {
      this.drawerShow = false;
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
